<!--
 * @Author: lbh
 * @Date: 2022-12-27 14:25:40
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-13 12:15:39
 * @Description: file content
-->
<template>
  <div
    class="gsPOSGraphicText1-box px-gsPOSGraphicText1-box"
    :class="`edit_${Xindex}_box paddingTB__${configs.paddingTB} paddingLR__${configs.paddingLR}`"
    @click="clickItem('box')"
    :style="`background-image:url(${configs.bgImage});`"
  >
    <img
      class="img"
      :src="configs.image"
      :alt="configs.alt"
    />
    <div class="right-b">

      <h1
        class="title"
        v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
      ></h1>
      <h2
        class="sub-title"
        v-html="$util.rex.getHtml(configs[`${nowL}subTitle`] || configs.subTitle)"
      ></h2>
      <div class="items">
        <div
          class="item"
          v-for="(item,index) in configs.items"
          :key="index"
        >
          <img
            class="i-icon"
            :src="item.icon"
          />
          <div
            class="i-title"
            v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          title: '',
          subTitle: '',
          image: '',
          bgImage: '',
          paddingTB: 200,
          paddingLR: 250,
          items: [
          ],
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      isEdit: false,
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    clickItem (e, x, t) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x, t);
      }
    },
  }
}
</script>

<style lang="less" scoped>
@media screen and(min-width:500px) {
  .gsPOSGraphicText1-box {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    .img {
      width: 680px;
      object-fit: contain;
    }
    .right-b {
      margin-left: 40px;
      .title {
        font-size: 80px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #145f9d;
      }
      .sub-title {
        font-size: 50px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #101010;
      }
      .items {
        margin-top: 60px;
        display: flex;
        flex-wrap: nowrap;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-left: 60px;
          &:nth-child(4n + 1) {
            margin-left: 0;
          }
          .i-icon {
            width: 98px;
            height: 98px;
          }
          .i-title {
            font-size: 30px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #101010;
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and(max-width:1000px) {
  .px-gsPOSGraphicText1-box {
    padding: 30px 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .right-b {
      .items {
        .item {
          .i-title {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}
@media screen and(max-width:500px) {
  .px-gsPOSGraphicText1-box {
    display: flex;
    align-items: center;
    padding: 40px 21px !important;
    background-size: cover;
    background-repeat: no-repeat;
    .img {
      width: calc(100% / 2.29);
      height: auto;
      object-fit: contain;
    }
    .right-b {
      margin-left: 5px;
      .title {
        font-size: 20px !important;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #145f9d;
      }
      .sub-title {
        font-size: 14px !important;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #101010;
      }
      .items {
        display: flex;
        margin-top: 10px !important;
        justify-content: space-between;
        flex-wrap: wrap;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;

          margin-left: 4px;
          &:nth-child(4n + 1) {
            margin-left: 0;
          }
          .i-icon {
            width: 20px;
            height: 20px;
          }
          .i-title {
            font-size: 8px !important;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #101010;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>